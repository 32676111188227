@charset 'UTF-8';
@import './setting/variables';

.C0007 {
	border-bottom:1px solid $line-gray;
	.container {
		@extend %clearfix;
		max-width:1344px; 
		margin:0 auto;
		padding:0 30px 0 30px;
		@include screen(custom, max, $max-md) {
			padding:0 30px 0 30px
		}
		@include screen(custom, max, $max-sm) {
			padding:0;
		}
	}
	// no filter
	.link-box {
		text-align: center;
		padding-bottom:41px;
		margin-top:-8px;
		@include screen(custom, max, $max-md) {
			margin-top: 27px;
		}
		.link-text {
			@include font-family($font-bold);
			color:$color-nightrider;
			text-decoration:none;
			text-transform:uppercase;
			&:after {
				background: url(/lg5-common/images/common/icons/link-right-nightrider.svg) no-repeat 0 1px;
			}
		}
	}
	// b2b
	/*
	&.in-b2b .result-box .product-list-box .list-box li .item .products-info .price-area{
		.msrp,
		.msrp + .purchase-price {
			margin-top: 8px;
			margin-bottom: 12px;
			@include screen(custom, max, $max-md) {
				// min-height:auto;
				margin-top:7px;
				margin-bottom: 13px;
			}
		}
	}
	*/
	/* 20190916 : LGEUS-11785 add */
	.filter-open-floating {
		display: none;
		@include screen(custom, max, 850) {
			&.float-active {
				margin-top: 0 !important;
			    display: flex;
			    overflow: hidden;
			    align-items: center;
			    padding: 0;
			    background: #e4e4e4;
			    font-size: 14px;
			    vertical-align: top;
			    height: 54px;
			    position: fixed;
			    top: 0;
			    left: 0;
			    width:100%;
			    z-index: 9999;
			    &.no-floating{
			    	position: relative;
			    }
			    .floating-filter {
			    	&:hover {
			    		text-decoration: none;
			    	}
			    	display: block;
				    float: none;
				    position: relative;
				    flex-basis: auto;
				    max-width: 50%;
				    height: 100%;
				    margin: 0;
				    padding-right: 21px;
				    padding-left: 21px;
				    border-radius: 0;
				    border: 0;
				    background: #767676;
				    color: #fff;
				    text-align: left;
				    line-height: 54px;
				    overflow: visible;
				    span{
				    	font-family: 'LG Smart SemiBold',sans-serif;
				    }
				    em {
					    display: inline-block;
					    line-height: 1.2;
					    word-break: break-all;
				    }
				    .filter-expand {
				    	margin-left: 2px;
					    vertical-align: middle;
					    color: #fff;
					    transform: rotate(90deg);
					    margin-top: -2px;
					}
					&.active{
						.filter-expand {
						    transform: rotate(270deg);
						}
					}
				}
				.float-sort-box {
					padding: 0 14px 0px 8px;
				    border: 0 !important;
				    flex: 1 0;
				    display: flex;
				    align-items: center;
				    float: left;
				    overflow: visible;
				    label[for=sortBy]{
				    	flex-basis: auto;
					    max-width: 60%;
					    line-height: 1;
					    font-size:14px;
					    margin-bottom:0px;
					    padding: 0px 8px 0px 8px;
					    color:#333;
					    font-family: 'LG Smart SemiBold',sans-serif;
				    }
				    .sort-select{
				    	flex: 1 0;
					    max-width: 100%;
					    width: 100%;
					    .sortBy{
						    height: 30px;
						    line-height: 25px;
						    font-size: 14px;
						    width: 100%;
						    text-align: left;
						    vertical-align: top;
						    text-decoration: none;
						    border: 1px solid #a4a4a4;
						    padding: 0px 33px 0px 8px;
						    max-height: 44px;
						    background: url('/lg5-common/images/common/icons/select-arrow-gray.svg') no-repeat 100% 50% #fff;
						    color: #767676;
						    -webkit-appearance:none;
						    option{
						    	color:#333333;
						    }
						    option[value=""][disabled] {
								display: none;
							}
					    }
				    }
				}

			}
		}
	}
	
	.filter-box {
		.float-filter-result{
			display: none;
			color:#6B6B6B;
			@include screen(custom, max, 850) {				
				height: 20px;
				border-bottom: 1px solid #e4e4e4;
				text-align: left;
				padding: 10px 0px 30px 10px;
				font-size: 14px;
				line-height: 20px;
				font-family: 'LG Smart SemiBold',sans-serif;
				.result-number{
					margin-right: 4px;
					color: #a50034;
				}
			}
		}
	}
	/* //20190916 : LGEUS-11785 add */
}
/* PJTWAUS-1 20200113 modify */
html[hcx="3"], html[hcx="4"] {
	.C0007 {
		.text-black, .text-white { 
			.banner-image {
				img {
					visibility: hidden;
				}
			}
		}
	}	
} 
/* //PJTWAUS-1 20200113 modify */
